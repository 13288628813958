<template>
  <div class="main-body">
    <div class="table-operator">
      <a-button type="primary" @click="showCreateMenuModal()"
        ><PlusOutlined />新增</a-button
      >
    </div>

    <a-table :columns="columns" :data-source="data" :pagination="false">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'action'">
          <span>
            <a @click="editMenu(record)">编辑</a>
            <a-divider type="vertical" />
            <a @click="showDeleteConfirm(record)">删除</a>
          </span>
        </template>
      </template>
    </a-table>
  </div>
  <a-modal
    v-model:visible="visible"
    title="添加"
    @ok="handleSubmit"
    @cancel="onCancel"
    :width="720"
  >
    <a-form ref="formRef" v-bind="layout" :model="formState">
      <a-form-item has-feedback label="名称" name="name">
        <a-input
          v-model:value="formState.name"
          type="text"
          autocomplete="off"
        />
      </a-form-item>
      <a-form-item has-feedback label="权限url" name="path">
        <a-input v-model:value="formState.url" type="text" autocomplete="off" />
      </a-form-item>
      <a-form-item has-feedback label="上级权限" name="roles">
        <a-tree-select
          v-model:value="parent_menu"
          show-search
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          placeholder="请选择"
          allow-clear
          :tree-data="treeData"
        >
        </a-tree-select>
      </a-form-item>
      <a-form-item has-feedback label="排序" name="sort" extra="数字越小越靠前">
        <a-input
          class="mini-input"
          type="number"
          v-model:value="formState.sort"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import {
  defineComponent,
  ref,
  onMounted,
  createVNode,
  reactive,
  watch,
} from "vue";
import * as api from "@/addons/manage/api";
import { message, Modal } from "ant-design-vue";
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};
const columns = [
  {
    title: "权限ID",
    dataIndex: "api_id",
    width: "10%",
  },
  {
    title: "权限名称",
    dataIndex: "name",
    width: "12%",
  },
  {
    title: "权限url",
    dataIndex: "url",
    width: "10%",
  },
  {
    title: "排序",
    dataIndex: "sort",
    width: "10%",
  },
  {
    title: "添加时间",
    dataIndex: "create_time",
  },
  {
    title: "操作",
    dataIndex: "action",
  },
];

export default defineComponent({
  components: {
    PlusOutlined,
  },
  setup() {
    const formRef = ref();
    const parent_menu = ref("顶级权限");
    const checked = ref(true);
    const formState = reactive({
      name: "",
      url: "",
      sort: "",
      parent_id: 0,
      api_id: 0,
    });

    const visible = ref(false);

    const data = ref([]);
    const handleRefresh = () => {
      api.api_list().then((res) => {
        data.value = res.data.list;
      });
    };
    const treeData = ref([]);

    const getTreeMenuData = () => {
      api.api_tree_list().then((res) => {
        treeData.value = res.data;
      });
    };
    const showDeleteConfirm = (record) => {
      Modal.confirm({
        title: "确定要删除【" + record.name + "】吗?",
        icon: createVNode(ExclamationCircleOutlined),
        content: "删除用户后将无法恢复，请谨慎操作！",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          api
            .api_del({ api_id: record.api_id })
            .then((res) => {
              console.log(res);
              message.success(res.message);
              handleRefresh();
            })
            .catch((error) => {
              message.error(error);
            });
        },
        onCancel() {
          // console.log("Cancel");
        },
      });
    };
    const showCreateMenuModal = () => {
      visible.value = true;
    };

    const handleSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          if (formState.api_id) {
            api.api_edit(formState).then(
              (res) => {
                message.success(res.message);
                visible.value = false;
                // 通知父组件刷新列表
                formRef.value.resetFields();
                handleRefresh();
              },
              (error) => {
                message.error(error);
              }
            );
          } else {
            api.api_add(formState).then(
              (res) => {
                message.success(res.message);
                visible.value = false;
                // 通知父组件刷新列表
                formRef.value.resetFields();
                handleRefresh();
              },
              (error) => {
                message.error(error);
              }
            );
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const onCancel = () => {};
    const addChild = (record) => {
      parent_menu.value = record.name;
      formState.parent_id = record.menu_id;
      visible.value = true;
    };

    const editMenu = (record) => {
      api.api_info({ api_id: record.api_id }).then((res) => {
        parent_menu.value = res.data.parent_name;
        formState.parent_id = res.data.parent_id;
        formState.api_id = res.data.api_id;
        formState.name = res.data.name;
        formState.url = res.data.url;
        formState.sort = res.data.sort;
        visible.value = true;
      });
    };
    onMounted(() => {
      handleRefresh();
      getTreeMenuData();
    });
    watch(parent_menu, () => {
      formState.parent_id = parent_menu.value;
    });
    return {
      columns,
      data,
      handleRefresh,
      showDeleteConfirm,
      layout,
      formState,
      visible,
      treeData,
      showCreateMenuModal,
      parent_menu,
      handleSubmit,
      onCancel,
      checked,
      formRef,
      addChild,
      editMenu,
    };
  },
});
</script>
